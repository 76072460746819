require('./bootstrap');

$('.delete-event').on('click', (e) => {
    const $btn = $(e.target);
    const eventId = $btn.data('event-id');

    Swal.fire({
        title: 'Bist du sicher?',
        text: 'Sie möchten dieses Ereignis löschen?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Löschen'
    }).then((result) => {
        if (result.isConfirmed) {
            $btn.siblings(`#delete-form-${eventId}`).submit();
        }
    })
});

$(document).ready(function() {
    $('#colorpicker').farbtastic('#color');
});
